import image from '../images/noImg.png';

export const CURRENCY = '$';
export const TIMEZONE = 'America/Los_Angeles';
export const NOIMG = image;
export const IMG = 'https://wrappedwithmusic.com';
// export const WITHIMG = 'https://wrappedwithmusic.com';
// export const IMG = 'http://192.168.1.227:8000';
// ? ====== BASE URL ======
export const URL = 'https://wrappedwithmusic.com/frontend';
// export const URL = 'http://192.168.1.227:8000/frontend';

export const SIGNUP = `${URL}/users`;
export const CATAGORIES = `${URL}/category`;
export const SUBCATAGORIES = `${URL}/subcategory`;
export const SONGTAMPLET = `${URL}/getsong`;
export const SCRIPT = `${URL}/guide`;
export const ORDER = `${URL}/order`;
export const MULTIORDER = `${URL}/multipleorder`;
export const SONG = `${URL}/song`;
export const MERGEAUDIO = `${URL}/mergeaudio`;
export const PAYMENT = `${URL}/completeorder`;
export const ADDTOCART = `${URL}/addtocart`;
export const GETCART = `${URL}/getcart-list`;
export const DELETECARTITEM = `${URL}/deletecart-item`;
export const GETSUBCATAGORY = `${URL}/getsubcategory`;
